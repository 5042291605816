import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SalesProtocolSelect from "./sales_protocol_select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {useDispatch, useSelector} from "react-redux";
import {fetchProtocols} from "../../data/redux/actions/protocol";
import {fetchSales} from "../../data/redux/actions/sales";

const SalesCustomerContainer = () => {
    const protocols = useSelector(state => state.protocols);
    const sales = useSelector(state => state.sales);
    let dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchProtocols());
    }, [dispatch]);

    const onSelectProtocol = (id) => {
        dispatch(fetchSales(id));
    };

    if (protocols.loaded) {
        return (
            <Box m={2}>
                <Typography variant={"h3"}>Customers</Typography>
                <Box mt={2} mb={5}>
                    <Divider/>
                </Box>
                <SalesProtocolSelect protocols={protocols} onSelectProtocol={onSelectProtocol}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Date of sale</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sales.sales.map(sale => (
                            <TableRow key={sale.email}>
                                <TableCell component="th" scope="row">
                                    {sale.displayName}
                                </TableCell>
                                <TableCell>{sale.email}</TableCell>
                                <TableCell>{new Date(sale.lastBoughtTime).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    }

    return <div/>;
};

export default SalesCustomerContainer;