import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ProtocolIcon from "@material-ui/icons/FormatListBulleted";
import SalesTrendsIcon from "@material-ui/icons/TrendingUp";
import CustomerIcon from "@material-ui/icons/Person";
import MeIcon from "@material-ui/icons/AccountCircle";
import Routes from "./routes";
import {Link} from "react-router-dom";
import {useFirebase} from "react-redux-firebase";
import {useSelector} from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    menuLink: {
        textDecoration: 'none',
        color: "rgba(0, 0, 0, 0.87)",
    },
    avatar: {
        margin: 10,
    },
}));

function Main() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const auth = useSelector(state => state.firebase.auth);
    const protocols = useSelector(state => state.protocols);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const profileMenuOpen = Boolean(anchorEl);
    const firebase = useFirebase();

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    async function logout() {
        await firebase.logout();
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Logs4Dogs Store Admin
                    </Typography>
                    <Typography variant="body1" noWrap>
                        {auth.displayName}
                    </Typography>
                    <div>
                        <Avatar alt="Remy Sharp" onClick={handleMenu}
                                src={auth.photoURL} className={classes.avatar}/>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={profileMenuOpen}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
                {protocols.isLoading && <LinearProgress color="secondary"/>}

            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <Link to="/protocols" className={classes.menuLink}>
                        <ListItem button key="My protocols">
                            <ListItemIcon><ProtocolIcon/></ListItemIcon>
                            <ListItemText primary="My protocols"/>
                        </ListItem>
                    </Link>
                    <Link to="/sales/statistics" className={classes.menuLink}>
                        <ListItem button>
                            <ListItemIcon><SalesTrendsIcon/></ListItemIcon>
                            <ListItemText primary="Sales statistics"/>
                        </ListItem>
                    </Link>
                    <Link to="/sales/customers" className={classes.menuLink}>
                        <ListItem button>
                            <ListItemIcon><CustomerIcon/></ListItemIcon>
                            <ListItemText primary="Customers"/>
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <List>
                    <Link to="/me" className={classes.menuLink}>
                        <ListItem button>
                            <ListItemIcon><MeIcon/></ListItemIcon>
                            <ListItemText primary="Me"/>
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Routes/>
            </main>
        </div>
    );
}

export default Main;
