import {
    ADD_TRAIL,
    ADD_TRAIL_FULFILLED,
    ADD_TRAIL_REJECTED,
    DELETE_TRAIL,
    DELETE_TRAIL_FULFILLED,
    DELETE_TRAIL_REJECTED,
    SUBSCRIBE_TRAILS,
    SUBSCRIBE_TRAILS_FULFILLED,
    SUBSCRIBE_TRAILS_REJECTED,
    SUBSCRIBE_TRAILS_UPDATING,
    UPDATE_TRAIL,
    UPDATE_TRAIL_FULFILLED,
    UPDATE_TRAIL_REJECTED
} from "../actions/actions";
import {sortTrails} from "../../../utils/sort";
import updateImmutableStateList from "../../../utils/state";


export default function reducer(state = {
    trails: [],
    isLoading: false,
    isUpdating: false,
    loaded: false,
    error: false,
    errorMessage: '',
}, action) {

    switch (action.type) {
        case SUBSCRIBE_TRAILS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ''};

        case SUBSCRIBE_TRAILS_UPDATING:
            return {...state, isUpdating: true};


        case SUBSCRIBE_TRAILS_FULFILLED:
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                loaded: true,
                trails: updateImmutableStateList(state.trails, action.payload, sortTrails),
            };

        case SUBSCRIBE_TRAILS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case ADD_TRAIL:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ''};

        case ADD_TRAIL_FULFILLED:
            return {...state, isLoading: false, loaded: true};

        case ADD_TRAIL_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case UPDATE_TRAIL:
            return {...state, /*isLoading: false,*/ loaded: true, error: false, errorMessage: ''};

        case UPDATE_TRAIL_FULFILLED:
            return {
                ...state,
                loaded: true,
                trails: state.trails.map(trail => trail.id === action.payload.id ? action.payload : trail),
                error: false,
                errorMessage: ''
            };

        case UPDATE_TRAIL_REJECTED:
            return {...state, /*isLoading: false,*/ loaded: false, error: true, errorMessage: action.payload};

        case DELETE_TRAIL_REJECTED:
            return {...state, loaded: false, error: true, errorMessage: action.payload};

        case DELETE_TRAIL:
            return {...state, loaded: true, error: false, errorMessage: ''};

        case DELETE_TRAIL_FULFILLED:
            return {
                ...state,
                loaded: true,
                error: false,
                errorMessage: ''

            };


        default:
            return state;

    }
}
