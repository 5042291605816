import React, {useState} from 'react';
import TrailNewDialog from "./trail_new_dialog";
import {setCurrentProtocol} from "../../../data/redux/actions/protocol";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import CardContent from "@material-ui/core/CardContent";
import TrailList from "./trail_list";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ConfirmDialog from "../../../common/confirm_dialog";
import {addTrail, deleteTrail, fetchTrails, updateTrail} from "../../../data/redux/actions/trail";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandTrailsIcon from "./expand_trails_icon";


const useStyles = makeStyles(theme => ({
    cardActions: {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "flex-end",
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const SliceCard = ({protocol}) => {
    const [showNewSliceDialog, setShowNewSliceDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [trailToRemove, setTrailToRemove] = useState(null);
    const [isTrailListOpen, setIsTrailListOpen] = useState(false);
    const trail = useSelector(state => state.trail);
    const currentProtocol = useSelector(state => state.protocols.currentProtocol);
    const dispatch = useDispatch();
    const classes = useStyles();


    function showTrailList(protocol) {
        if (!isTrailListOpen) {
            dispatch(setCurrentProtocol(protocol));
            dispatch(fetchTrails(protocol.id));
        }
        setIsTrailListOpen(!isTrailListOpen);
    }

    function isTrailListExpanded() {
        return (currentProtocol.id === protocol.id) && isTrailListOpen;
    }

    function onNewSlice() {
        setShowNewSliceDialog(true);
    }


    function onDeleteSlice(trail) {
        setTrailToRemove(trail);
        setShowConfirmDialog(true);
    }


    function onDeleteSliceConfirm() {
        dispatch(deleteTrail(protocol.id, trailToRemove))
        setShowConfirmDialog(false);
    }

    function onDeleteSliceCancel() {
        setShowConfirmDialog(false);
    }


    function onSaveTrail(trail) {
        dispatch(updateTrail(protocol.id, trail));
    }


    return (
        <React.Fragment>
            <ConfirmDialog title="Delete slice?"
                           message="Do you really want to delete the slice?"
                           onConfirm={onDeleteSliceConfirm}
                           onNotConfirm={onDeleteSliceCancel}
                           open={showConfirmDialog}
            />
            <TrailNewDialog
                open={showNewSliceDialog}
                onClose={() => setShowNewSliceDialog(false)}
                onAdd={(trail) => {
                    setShowNewSliceDialog(false);
                    dispatch(addTrail(protocol.id, trail))
                }}
            />
            <Divider/>
            <CardActions disableSpacing className={classes.cardActions}>

                <Button
                    style={{width: '150px', height: '36px'}}
                    color="secondary"
                    variant="outlined"
                    onClick={() => showTrailList(protocol)}
                    aria-expanded={isTrailListExpanded()}
                    aria-label="Show slices"
                >
                    {
                        trail.isLoading || protocol.isUpdating ?
                            <CircularProgress color="secondary" size={18}/> :
                            <ExpandTrailsIcon trailCount={protocol.trailCount} expanded={isTrailListExpanded()}/>

                    }

                </Button>
            </CardActions>
            <Collapse in={isTrailListExpanded()} timeout="auto" unmountOnExit>
                <CardActions className={classes.cardActions}>
                    <Box mr={2}>
                        <Fab aria-label="delete" color="secondary" size="small" onClick={onNewSlice}>
                            <AddIcon/>
                        </Fab>
                    </Box>
                </CardActions>
                <CardContent>

                    <TrailList
                        trails={trail.trails}
                        onDeleteSlice={onDeleteSlice}
                        onSaveSlice={onSaveTrail}
                    />

                </CardContent>
            </Collapse>
        </React.Fragment>
    );
};

export default SliceCard;
