import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import store from "./data/redux/store";
import {BrowserRouter} from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import firebase from "./config/firebase-config";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";
import LoginPage from "./features/auth/login_container";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#fff",
        },
        secondary: blue,
    },
    status: {
        danger: 'orange',
    },
});

const rrfConfig = {
    //userProfile: 'users',
    //useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    //enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance // <- needed if using firestore
};

function App(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <BrowserRouter basename='/'>
                        <LoginPage/>
                    </BrowserRouter>
                </ReactReduxFirebaseProvider>
            </Provider>
        </MuiThemeProvider>
    );
}

export default App;
