import React from 'react';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ProtocolCard from "./protocol_card";
import {useSelector} from "react-redux";


const ProtocolList = () => {

    let protocols = useSelector(state => state.protocols);

    if (protocols.error) {
        return <p>{protocols.errorMessage.message}</p>
    }

    if (protocols.loaded) {
        return (
            <Box m={2}>
                <Typography variant={"h3"}>Protocols</Typography>
                <Box mt={2}>
                    <Divider/>
                </Box>
                {protocols.protocols.map(protocol =>
                    <ProtocolCard key={protocol.id} protocol={protocol}/>
                )}
            </Box>
        );
    }

    return <div/>;

};

export default ProtocolList;
