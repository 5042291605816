export const Protocol = {
    title: "",
    description: "",
    authorDisplayName: "",
    authorId: "",
    stars: [],
    downloads: 0,
    createdAt: null,
    lastModified: null,
    isFree: true,
    price: 0,
    trailCount: 0,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/logs4dogs.appspot.com/o/assets%2Fdefaults%2Fdefault-protocol-image.png?alt=media&token=65ec845d-0b43-449c-b7ad-7e17ccdd4c5b",
};

export const Trail = {
    title: "",
    criteriaDescription: "",
    trailCount: 0,
    onLeash: false,
    resetDescription: "",
    trainerDistance: 0,
    trainerPosition: "",
    cue: "",
    createdAt: null,
    lastModified: null,
};