import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import {TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Trail} from "../../../models/protocol";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function TrailNewDialog(props) {
    const {edit, open, onClose, onAdd} = props;
    const [slice, setSlice] = React.useState(Trail);

    const handleChangeString = event => {
        setSlice({...slice, [event.target.id]: event.target.value});
    };

    const handleChangeInt = event => {
        setSlice({...slice, [event.target.id]: parseInt(event.target.value)});
    };

    const handleChangeCheckbox = event => {
        setSlice({...slice, [event.target.id]: event.target.checked});
    };

    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    {edit ? "Edit slice" : "New slice"}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <form noValidate autoComplete="off">
                            <TextField
                                id="title"
                                label="Title"
                                value={slice.title}
                                onChange={handleChangeString}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                id="criteriaDescription"
                                label="Criteria"
                                value={slice.criteriaDescription}
                                onChange={handleChangeString}
                                margin="normal"
                                fullWidth
                                multiline
                                rowsMax={10}
                                rows={5}
                            />
                            <TextField
                                id="resetDescription"
                                label="Reset description"
                                value={slice.resetDescription}
                                onChange={handleChangeString}
                                margin="normal"
                                fullWidth
                                multiline
                                rowsMax={10}
                                rows={5}
                            />
                            <TextField
                                id="trainerPosition"
                                label="Trainer position"
                                value={slice.trainerPosition}
                                onChange={handleChangeString}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                id="cue"
                                label="Cue"
                                value={slice.cue}
                                onChange={handleChangeString}
                                margin="normal"
                                fullWidth
                            />
                            <Box display="flex" justifyContent="space-between" alignItems="baseline">
                                <TextField
                                    id="trailCount"
                                    label="Trail count"
                                    value={slice.trailCount}
                                    onChange={handleChangeInt}
                                    margin="normal"
                                    type="number"
                                />
                                <TextField
                                    id="trainerDistance"
                                    label="Trainer distance"
                                    value={slice.trainerDistance}
                                    onChange={handleChangeInt}
                                    margin="normal"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">meters</InputAdornment>,
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={slice.onLeash}
                                            onChange={handleChangeCheckbox}
                                            id="onLeash"
                                            color={"default"}
                                        />
                                    }
                                    label="On leash"
                                />
                            </Box>
                        </form>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onAdd(slice)} color="secondary" variant="contained">
                        {edit ? "Update slice" : "Add slice"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
