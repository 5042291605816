import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import SliceEdit from "./slice_edit";
import SliceListItem from "./slice_list_item";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";


const SliceItem = ({slice, onDeleteSlice, onSaveSlice}) => {
    const [isEditing, setIsEditing] = React.useState(false);

    const onSaveSliceFinished = (slice) => {
        onSaveSlice(slice);
        setIsEditing(!isEditing);
    };
    return (
        <React.Fragment>
            <ListItem>
                {isEditing ? <SliceEdit sliceToEdit={slice} onSaveSlice={onSaveSliceFinished} onCancelEdit={() => setIsEditing(false)}/> : <SliceListItem slice={slice}/>}
                {isEditing
                    ? null
                    :
                    <ListItemSecondaryAction>

                        <IconButton onClick={() => setIsEditing(!isEditing)} aria-label="settings">
                            <EditIcon/>
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => onDeleteSlice(slice)}
                                    disabled={isEditing}>
                            <DeleteIcon/>
                        </IconButton>
                    </ListItemSecondaryAction>
                }
            </ListItem>

            <Divider/>
        </React.Fragment>
    );
};

export default SliceItem;