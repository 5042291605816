import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    description: {
        whiteSpace: "pre-wrap",
    },
}));
const ProtocolView = (props) => {

    const classes = useStyles();
    const {protocol} = props;

    return (
        <Box mt={2}>
            <Grid container direction="column">
                <Grid item>
                    <Typography gutterBottom variant="h5" component="h2">
                        {protocol.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="caption" color="textSecondary" component="h5">
                        {protocol.authorDisplayName ? protocol.authorDisplayName : null}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="caption" color="textSecondary" component="h5">
                        Created: {protocol.createdAt !== null && protocol.createdAt.toDate().toLocaleString()}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="caption" color="textSecondary" component="h5">
                        Modified: {protocol.lastModified !== null && protocol.lastModified.toDate().toLocaleString()}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box mt={2}>
                        <Typography gutterBottom variant="body2" color="textSecondary" component="p"
                                    className={classes.description}>
                            {protocol.description}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProtocolView;
