import {FETCH_SALES, FETCH_SALES_FULFILLED, FETCH_SALES_REJECTED} from "../actions/actions";

export default function reducer(state = {
    sales: [],
    isLoading: false,
    loaded: false,
    error: false,
    errorMessage: "",
}, action) {

    switch (action.type) {
        case FETCH_SALES:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case FETCH_SALES_FULFILLED:
            return {...state, isLoading: false, loaded: true, sales: action.payload};

        case FETCH_SALES_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        default:
            return state;
    }
};
