export const SUBSCRIBE_PROTOCOLS = "SUBSCRIBE_PROTOCOLS";
export const SUBSCRIBE_PROTOCOLS_FULFILLED = "SUBSCRIBE_PROTOCOLS_FULFILLED";
export const SUBSCRIBE_PROTOCOLS_UPDATING = "SUBSCRIBE_PROTOCOLS_UPDATING";
export const SUBSCRIBE_PROTOCOLS_REJECTED = "SUBSCRIBE_PROTOCOLS_REJECTED";

export const FETCH_PROTOCOL = "FETCH_PROTOCOL";
export const FETCH_PROTOCOL_FULFILLED = "FETCH_PROTOCOL_FULFILLED";
export const FETCH_PROTOCOL_REJECTED = "FETCH_PROTOCOL_REJECTED";

export const ADD_PROTOCOL = "ADD_PROTOCOL";
export const ADD_PROTOCOL_FULFILLED = "ADD_PROTOCOL_FULFILLED";
export const ADD_PROTOCOL_REJECTED = "ADD_PROTOCOL_REJECTED";

export const UPDATE_PROTOCOL = "UPDATE_PROTOCOL";
export const UPDATE_PROTOCOL_FULFILLED = "UPDATE_PROTOCOL_FULFILLED";
export const UPDATE_PROTOCOL_REJECTED = "UPDATE_PROTOCOL_REJECTED";

export const DELETE_PROTOCOL = "DELETE_PROTOCOL";
export const DELETE_PROTOCOL_FULFILLED = "DELETE_PROTOCOL_FULFILLED";
export const DELETE_PROTOCOL_REJECTED = "DELETE_PROTOCOL_REJECTED";

export const SET_CURRENT_PROTOCOL = "SET_CURRENT_PROTOCOL";

export const FETCH_SALES = "FETCH_SALES";
export const FETCH_SALES_FULFILLED = "FETCH_SALES_FULFILLED";
export const FETCH_SALES_REJECTED = "FETCH_SALES_REJECTED";

export const SUBSCRIBE_TRAILS = "SUBSCRIBE_TRAILS";
export const SUBSCRIBE_TRAILS_UPDATING = "SUBSCRIBE_TRAILS_UPDATING";
export const SUBSCRIBE_TRAILS_FULFILLED = "SUBSCRIBE_TRAILS_FULFILLED";
export const SUBSCRIBE_TRAILS_REJECTED = "SUBSCRIBE_TRAILS_REJECTED";

export const ADD_TRAIL = "ADD_TRAIL";
export const ADD_TRAIL_FULFILLED = "ADD_TRAIL_FULFILLED";
export const ADD_TRAIL_REJECTED = "ADD_TRAIL_REJECTED";

export const UPDATE_TRAIL = "UPDATE_TRAIL";
export const UPDATE_TRAIL_FULFILLED = "UPDATE_TRAIL_FULFILLED";
export const UPDATE_TRAIL_REJECTED = "UPDATE_TRAIL_REJECTED";

export const DELETE_TRAIL = "DELETE_TRAIL";
export const DELETE_TRAIL_FULFILLED = "DELETE_TRAIL_FULFILLED";
export const DELETE_TRAIL_REJECTED = "DELETE_TRAIL_REJECTED";
