import React from 'react';

const MeContainer = () => {
    return (
        <div>
            Me
        </div>
    );
};

export default MeContainer;