import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import ProtocolView from "./protocol_view";
import SaveIcon from "@material-ui/icons/Save";
import ProtocolEdit from "./protocol_edit";
import {useDispatch} from "react-redux";
import {deleteProtocol, updateProtocol} from "../../data/redux/actions/protocol";
import ProtocolImage from "../../common/protocol_image";
import Chip from "@material-ui/core/Chip";
import DownloadedIcon from "@material-ui/icons/CloudDownload";
import Rating from "@material-ui/lab/Rating";
import {getRating} from "../../common/tools";
import ConfirmDialog from "../../common/confirm_dialog";
import SliceCard from "./slice/slice_card";

const useStyles = makeStyles(theme => ({
    card: {
        width: "90%",
        padding: 0,
    },
    cardContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: 0,
    },
    cardActions: {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "flex-end",
    },
    margin: {
        margin: theme.spacing(1),
    },
    downloaded: {
        color: "rgba(0, 0, 0, 0.54)",
    },
    details: {
        height: "100%",
    },
    chipFree: {
        padding: 0,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

export default function ProtocolCard(props) {

    const {protocol} = props;
    const classes = useStyles();
    const [isEditing, setIsEditing] = React.useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const dispatch = useDispatch();
    const [protocolToEdit, setProtocolToEdit] = React.useState(protocol);

    function handleEditingClick(protocol) {
        setIsEditing(!isEditing);
        setProtocolToEdit(protocol);
    }

    function onEdit(protocol) {
        setProtocolToEdit(protocol);
    }

    function publish() {
        dispatch(updateProtocol({...protocol, published: !protocol.published}));
    }

    function onSave() {
        setIsEditing(!isEditing);
        dispatch(updateProtocol({...protocol, ...protocolToEdit}));
    }

    function onDeleteProtocolClick() {
        setShowConfirmDialog(true);
    }

    function onDeleteProtocolConfirm() {
        dispatch(deleteProtocol(protocol));
        setShowConfirmDialog(false);
    }

    function onDeleteProtocolCancel() {
        setShowConfirmDialog(false);
    }

    return (
        <React.Fragment>
            <ConfirmDialog title="Delete protocol?"
                           message="Do you really want to delete the protocol?"
                           onConfirm={onDeleteProtocolConfirm}
                           onNotConfirm={onDeleteProtocolCancel}
                           open={showConfirmDialog}
            />
            <Box m={5}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>

                        <Grid container spacing={4} wrap="nowrap">
                            <Grid item>
                                <ProtocolImage isEditing={isEditing} protocol={protocol} onUpdate={onEdit}/>
                            </Grid>
                            <Grid item>
                                {isEditing ? <ProtocolEdit protocol={protocol} onUpdate={onEdit}/> :
                                    <ProtocolView protocol={protocol}/>}
                            </Grid>
                        </Grid>
                        <Box m={0}>
                            <Grid container justify="space-between" alignItems="baseline">
                                <Grid item>
                                    <Grid container>
                                        <Box mt={2} mb={1} alignItems="center" justify="space-between" display="flex">
                                            <Box className={classes.downloaded} display="flex" alignItems="center"
                                                 mr={2}>
                                                <Box>
                                                    {protocol.isFree
                                                        ?
                                                        <Chip label="FREE" className={classes.chipFree}/>
                                                        :
                                                        <Chip label={protocol.price + "$"}
                                                              className={classes.chipFree}/>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box className={classes.downloaded} display="flex" alignItems="center"
                                                 mr={2}>
                                                <DownloadedIcon/>
                                                <Box ml={1}>
                                                    ({protocol.downloads})
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Rating value={getRating(protocol.stars)} readOnly/>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Button variant="outlined" size="small" color="secondary"
                                                onClick={publish}
                                                className={classes.margin} disabled={isEditing}>
                                            {protocol.published ? "Unpublish" : "Publish"}
                                        </Button>
                                        {isEditing
                                            ?
                                            <IconButton onClick={onSave} aria-label="settings">
                                                <SaveIcon/>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => handleEditingClick(protocol)} aria-label="settings">
                                                <EditIcon/>
                                            </IconButton>
                                        }

                                        <IconButton onClick={onDeleteProtocolClick} aria-label="settings"
                                                    disabled={isEditing}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                    <SliceCard protocol={protocol}/>
                </Card>
            </Box>
        </React.Fragment>
    );
}
