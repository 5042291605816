import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {isEmpty, isLoaded, useFirebase, withFirebase} from 'react-redux-firebase'
import Main from "../main/main";
import LoginPage from "./login_page";
import LoadingPage from "./loading_page"; // optional

function LoginContainer({auth}) {
    const firebase = useFirebase();
    const [isAuthor, setIsAuthor] = useState(false);


    if (!isLoaded(auth)) return <LoadingPage/>;
    if (isEmpty(auth)) return <LoginPage/>;
    // TODO: Should this be moved to redux or done in another way?
    firebase.auth().currentUser
        .getIdTokenResult()
        .then(tokenResult => setIsAuthor(tokenResult.claims.author));
    if (isAuthor) return <Main/>;
    return <em>Forbidden</em>;


    //return <Main/>;
}

LoginContainer.propTypes = {
    auth: PropTypes.object
};

export default compose(
    withFirebase,
    connect(({firebase: {auth}}) => ({auth}))
)(LoginContainer)