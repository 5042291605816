import {
    ADD_PROTOCOL,
    ADD_PROTOCOL_FULFILLED,
    ADD_PROTOCOL_REJECTED,
    DELETE_PROTOCOL,
    DELETE_PROTOCOL_FULFILLED,
    DELETE_PROTOCOL_REJECTED,
    FETCH_PROTOCOL,
    FETCH_PROTOCOL_FULFILLED,
    FETCH_PROTOCOL_REJECTED,
    SET_CURRENT_PROTOCOL,
    SUBSCRIBE_PROTOCOLS,
    SUBSCRIBE_PROTOCOLS_FULFILLED,
    SUBSCRIBE_PROTOCOLS_UPDATING,
    UPDATE_PROTOCOL,
    UPDATE_PROTOCOL_FULFILLED,
    UPDATE_PROTOCOL_REJECTED
} from "./actions";

import {protocolStore} from "../../../config/firebase-config";
import * as firebase from "firebase";

export function fetchProtocols() {
    return function (dispatch) {
        dispatch({type: SUBSCRIBE_PROTOCOLS});
        const user = firebase.auth().currentUser;

        protocolStore.where("authorId", "==", user.uid)
            .orderBy('createdAt', 'desc')
            .onSnapshot(snapshot => {
                dispatch({type: SUBSCRIBE_PROTOCOLS_UPDATING});
                let protocols = [];
                snapshot
                    .docChanges()
                    .forEach(change => {
                        protocols.push(
                            {
                                item: {
                                    ...change.doc.data(),
                                    id: change.doc.id
                                },
                                type: change.type
                            }
                        )
                    }
                    );
                dispatch({type: SUBSCRIBE_PROTOCOLS_FULFILLED, payload: protocols});
            });
    }
}

export function fetchProtocol(id) {
    return function (dispatch) {
        dispatch({type: FETCH_PROTOCOL});

        protocolStore.doc(id).get({source: "default"}).then((result) => {
            dispatch({type: FETCH_PROTOCOL_FULFILLED, payload: result});
        }).catch((error) => {
            dispatch({type: FETCH_PROTOCOL_REJECTED, payload: error})
        });
    }
}

export function addProtocol(protocol) {
    return function (dispatch) {
        dispatch({type: ADD_PROTOCOL});

        protocolStore.add(protocol).then((result) => {
            dispatch({type: ADD_PROTOCOL_FULFILLED});
        }).catch((error) => {
            dispatch({type: ADD_PROTOCOL_REJECTED, payload: error});
        })
    }

}

export function updateProtocol(protocol) {
    return function (dispatch) {
        dispatch({type: UPDATE_PROTOCOL});

        protocolStore
            .doc(protocol.id)
            .update(protocol)
            .then((result) => dispatch({type: UPDATE_PROTOCOL_FULFILLED, payload: protocol}))
            .catch((error) => dispatch({type: UPDATE_PROTOCOL_REJECTED, payload: error}));

    }
}

export function deleteProtocol(protocol) {
    return function (dispatch) {
        dispatch({type: DELETE_PROTOCOL});
        protocolStore.doc(protocol.id).delete().then(result => {
            dispatch({type: DELETE_PROTOCOL_FULFILLED, payload: protocol});
        }).catch(error => {
            dispatch({type: DELETE_PROTOCOL_REJECTED, error});
        })
    }
}

export function setCurrentProtocol(protocol) {
    return function (dispatch) {
        dispatch({type: SET_CURRENT_PROTOCOL, payload: protocol});

    }
}
