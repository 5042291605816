import React from 'react';
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import SliceItem from "./slice_item";

const TrailList = (props) => {
    const {trails, onDeleteSlice, onSaveSlice} = props;

    return (
        <React.Fragment>
            <Divider/>
            <List>
                {trails.map(trail => <SliceItem key={trail.id} slice={trail} onDeleteSlice={onDeleteSlice}
                                                onSaveSlice={onSaveSlice}/>)}
            </List>
        </React.Fragment>
    );
};

export default TrailList;