import {combineReducers} from "redux";
import protocols from "./protocol";
import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";
import sales from "./sales";
import trails from './trail';

export default combineReducers({
    protocols: protocols,
    sales: sales,
    trail: trails,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});