const sortTrails = (trails) => {
    return trails.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
};

const sortProtocols = (protocols) => {
    return protocols.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)).reverse();

};

module.exports = {
    sortTrails,
    sortProtocols,
};