import React from 'react';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    orderAvatar: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.light,
    },
}));

const SliceListItem = ({slice}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <ListItemAvatar>
                <Avatar className={classes.orderAvatar}>{slice.order}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={slice.title}
                secondary={slice.criteriaDescription}
            />
        </React.Fragment>
    );
};

export default SliceListItem;