import React from 'react';
import {Box} from "@material-ui/core";

const LoadingPage = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
            <span>Loading...</span>
        </Box>
    );
};

export default LoadingPage;