import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addProtocol} from "../../data/redux/actions/protocol";
import {Button, Card, CardActions, CardContent, CardHeader, TextField} from "@material-ui/core";
import {Protocol} from "../../models/protocol";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const AddProtocol = () => {
    const [protocol, setProtocol] = React.useState(Protocol);
    const ProtocolLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} to="/protocols"/>);
    const auth = useSelector(state => state.firebase.auth);
    const dispatch = useDispatch();

    const handleChange = event => {
        setProtocol({...protocol, [event.target.id]: event.target.value});
    };

    const add = () => {
        protocol.authorId = auth.uid;
        protocol.authorDisplayName = auth.displayName;
        dispatch(addProtocol(protocol));
    };

    return (
        <div>
            <Card>
                <CardHeader title="New protocol"/>
                <CardContent>
                    <form noValidate autoComplete="off">
                        <TextField
                            id="title"
                            label="Title"
                            value={protocol.title}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            id="description"
                            label="Description"
                            value={protocol.description}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            multiline
                            rowsMax={10}
                            rows={5}
                        />

                    </form>
                </CardContent>
                <CardActions>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item>
                            <Button component={ProtocolLink} color="secondary" variant="contained">Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={add} component={ProtocolLink} color="secondary" variant="contained">Add
                                protocol</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>

        </div>
    );
};

export default AddProtocol;