import React from 'react';
import {Route} from "react-router-dom";
import ProtocolContainer from "../protocol/protocol_container";
import DashboardContainer from "../dashboard/dashboard_container";
import SalesStatisticsContainer from "../sales/sales_statistics_container";
import MeContainer from "../me/me_container";
import AddProtocol from "../protocol/add_protocol";
import SalesCustomerContainer from "../sales/sales_customer_container";

const Routes = () => {
    return (
        <div>
            <Route exact path='/' component={DashboardContainer}/>
            <Route exact path='/protocols' component={ProtocolContainer}/>
            <Route exact path='/protocols/new' component={AddProtocol}/>
            <Route exact path='/sales/statistics' component={SalesStatisticsContainer}/>
            <Route exact path='/me' component={MeContainer}/>
            <Route exact path='/sales/customers' component={SalesCustomerContainer}/>
        </div>

    );
};

export default Routes;