import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 230,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const SalesProtocolSelect = ({protocols, onSelectProtocol}) => {
    const classes = useStyles();
    const [selectedProtocol, setSelectedProtocol] = React.useState(0);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {
        setSelectedProtocol(event.target.value);
        onSelectProtocol(event.target.value);
    };

    return (
        <form className={classes.root} autoComplete="off">
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    Protocols
                </InputLabel>
                <Select
                    value={selectedProtocol}
                    onChange={handleChange}
                    labelWidth={labelWidth}
                    inputProps={{
                        name: 'protocol',
                        id: 'outlined-age-simple',
                    }}
                >
                    <MenuItem value="0"><em>Select a protocol</em></MenuItem>
                    {protocols.protocols.map(protocol => <MenuItem key={protocol.id} value={protocol.id}>{protocol.title}</MenuItem>)}

                </Select>
            </FormControl>
        </form>
    );
};

export default SalesProtocolSelect;