import {protocolStore} from "../../../config/firebase-config";
import {FETCH_SALES, FETCH_SALES_FULFILLED, FETCH_SALES_REJECTED} from "./actions";

export function fetchSales(id) {
    return function (dispatch) {
        dispatch({type: FETCH_SALES});


        protocolStore.doc(id).collection("downloads").get({source: "default"}).then((result) => {
            let sales = [];
            result.forEach(sale => sales.push({...sale.data()}));
            dispatch({type: FETCH_SALES_FULFILLED, payload: sales});
        }).catch((error) => {
            dispatch({type: FETCH_SALES_REJECTED, payload: error})
        });

    }
}