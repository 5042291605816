import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    cardActions: {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "flex-end",
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const ExpandTrailsIcon = ({trailCount, expanded}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            Trails ({trailCount ? trailCount : 0})
            <ExpandMoreIcon className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
            })}/>
        </React.Fragment>
    );
};

export default ExpandTrailsIcon;