import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import {TextField} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const ProtocolEdit = (props) => {

    const {protocol, onUpdate} = props;
    const [values, setValues] = useState(protocol);


    const handleChangeString = event => {
        setValues({...values, [event.target.id]: event.target.value});
        onUpdate({...values, [event.target.id]: event.target.value});
    };

    const handleChangeInt = event => {
        setValues({...values, [event.target.id]: parseInt(event.target.value)});
        onUpdate({...values, [event.target.id]: parseInt(event.target.value)});
    };



    const handleChangeCheckbox = event => {
        setValues({...values, [event.target.id]: event.target.checked});
        onUpdate({...values, [event.target.id]: event.target.checked});
    };

    return (
        <Box mt={2}>
            <form noValidate autoComplete="off">
                <TextField
                    id="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChangeString}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    id="description"
                    label="Description"
                    value={values.description}
                    onChange={handleChangeString}
                    margin="normal"
                    fullWidth
                    multiline
                    rowsMax={10}
                    rows={5}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.isFree}
                            onChange={handleChangeCheckbox}
                            id="isFree"
                            color={"default"}
                        />
                    }
                    label="Free"
                />
                <TextField
                    id="price"
                    label="Price"
                    value={values.price}
                    onChange={handleChangeInt}
                    margin="normal"
                    fullWidth
                    type="number"
                    disabled={values.isFree}
                />

            </form>
        </Box>
    );
};

export default ProtocolEdit;
