import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {fetchProtocols} from "../../data/redux/actions/protocol";
import Chart from "react-google-charts";

const SalesStatisticsContainer = () => {
    const protocols = useSelector(state => state.protocols);
    let dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchProtocols());
    }, [dispatch]);


    const getProtocolStats = () => {
        let stats = [['Protocol', 'Sales/downloads']];
        protocols.protocols.forEach(protocol => {
            stats.push([protocol.title, protocol.downloads]);
        });

        return stats;
    };

    if (protocols.loaded) {
        return (
            <Box m={2}>
                <Typography variant={"h3"}>Sales statistics</Typography>
                <Box mt={2} mb={5}>
                    <Divider/>
                </Box>
                <Chart
                    width={'100%'}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={getProtocolStats()}
                    options={{
                        title: 'Sales/downloads per protocol',
                        chartArea: {width: '50%'},
                        hAxis: {
                            title: 'Sales/downloads',
                            minValue: 0,
                        },
                        vAxis: {
                            title: 'Protocols',
                        },
                    }}
                />
            </Box>
        );
    }
    return <div/>;
};

export default SalesStatisticsContainer;