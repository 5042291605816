import {
    ADD_PROTOCOL,
    ADD_PROTOCOL_FULFILLED,
    ADD_PROTOCOL_REJECTED,
    DELETE_PROTOCOL,
    DELETE_PROTOCOL_FULFILLED,
    DELETE_PROTOCOL_REJECTED,
    FETCH_PROTOCOL,
    FETCH_PROTOCOL_FULFILLED,
    FETCH_PROTOCOL_REJECTED,
    SET_CURRENT_PROTOCOL,
    SUBSCRIBE_PROTOCOLS,
    SUBSCRIBE_PROTOCOLS_FULFILLED,
    SUBSCRIBE_PROTOCOLS_REJECTED,
    SUBSCRIBE_PROTOCOLS_UPDATING,
    UPDATE_PROTOCOL,
    UPDATE_PROTOCOL_FULFILLED,
    UPDATE_PROTOCOL_REJECTED
} from "../actions/actions";
import {sortProtocols} from "../../../utils/sort";
import updateImmutableStateList from "../../../utils/state";

export default function reducer(state = {
    protocols: [],
    currentProtocol: "",
    isLoading: false,
    isUpdating: false,
    loaded: false,
    error: false,
    errorMessage: "",
}, action) {

    switch (action.type) {
        case SUBSCRIBE_PROTOCOLS:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case SUBSCRIBE_PROTOCOLS_UPDATING:
            return {...state, isUpdating: true};

        case SUBSCRIBE_PROTOCOLS_FULFILLED:
            return {
                ...state,
                isLoading: false,
                loaded: true,
                isUpdating: false,
                protocols: updateImmutableStateList(state.protocols, action.payload, sortProtocols),
            };

        case SUBSCRIBE_PROTOCOLS_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case FETCH_PROTOCOL:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case FETCH_PROTOCOL_FULFILLED:
            return {
                ...state,
                isLoading: false,
                loaded: true,
                protocols: state.protocols.map(protocol => protocol.id === action.payload.id ? action.payload : protocol),
                error: false,
                errorMessage: ""
            };

        case FETCH_PROTOCOL_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case ADD_PROTOCOL:
            return {...state, isLoading: true, loaded: false, error: false, errorMessage: ""};

        case ADD_PROTOCOL_FULFILLED:
            return {...state, isLoading: false, loaded: true};

        case ADD_PROTOCOL_REJECTED:
            return {...state, isLoading: false, loaded: false, error: true, errorMessage: action.payload};

        case UPDATE_PROTOCOL:
            return {...state, /*isLoading: false,*/ loaded: true, error: false, errorMessage: ""};

        case UPDATE_PROTOCOL_FULFILLED:

            return state;


        case UPDATE_PROTOCOL_REJECTED:
            return {...state, /*isLoading: false,*/ loaded: false, error: true, errorMessage: action.payload};

        case DELETE_PROTOCOL_REJECTED:
            return {...state, loaded: false, error: true, errorMessage: action.payload};

        case DELETE_PROTOCOL:
            return {...state, loaded: true, error: false, errorMessage: ""};

        case DELETE_PROTOCOL_FULFILLED:
            return {
                ...state,
                loaded: true,
                protocols: state.protocols.filter(protocol => protocol.id !== action.payload.id),
                error: false,
                errorMessage: ""
            };

        case SET_CURRENT_PROTOCOL:
            return {...state, currentProtocol: action.payload};

        default:
            return state;

    }
}