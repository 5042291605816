import React from 'react';
import {TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

const SliceEdit = ({sliceToEdit, onCancelEdit, onSaveSlice}) => {
    const [slice, setSlice] = React.useState(sliceToEdit);

    const handleChangeString = event => {
        setSlice({...slice, [event.target.id]: event.target.value});
    };

    const handleChangeInt = event => {
        setSlice({...slice, [event.target.id]: parseInt(event.target.value)});
    };
    const handleChangeCheckbox = event => {
        setSlice({...slice, [event.target.id]: event.target.checked});
    };
    return (
        <Box border={1} borderRadius="borderRadius" borderColor="grey.300" p={4} mb={2} mt={1} width="100%">
            <form noValidate autoComplete="off">
                <TextField
                    id="title"
                    label="Title"
                    value={slice.title}
                    onChange={handleChangeString}
                    margin="normal"
                    fullWidth
                />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box mr={4} width="100%">
                        <TextField
                            id="criteriaDescription"
                            label="Criteria"
                            value={slice.criteriaDescription}
                            onChange={handleChangeString}
                            margin="normal"
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </Box>
                    <Box width="100%">
                        <TextField
                            id="resetDescription"
                            label="Reset description"
                            value={slice.resetDescription}
                            onChange={handleChangeString}
                            margin="normal"
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box mr={4} width="100%">
                        <TextField
                            id="cue"
                            label="Cue"
                            value={slice.cue}
                            onChange={handleChangeString}
                            margin="normal"
                            fullWidth

                        />
                    </Box>
                    <Box width="100%">
                        <TextField
                            id="trainerPosition"
                            label="Trainer position"
                            value={slice.trainerPosition}
                            onChange={handleChangeString}
                            margin="normal"
                            fullWidth
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                    <Box mr={4}>
                        <TextField
                            id="trainerDistance"
                            label="Trainer distance"
                            value={slice.trainerDistance}
                            onChange={handleChangeInt}
                            margin="normal"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">meters</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box mr={4}>
                        <TextField
                            id="trailCount"
                            label="Trail count"
                            value={slice.trailCount}
                            onChange={handleChangeInt}
                            margin="normal"
                            type="number"
                        />
                    </Box>
                    <Box mr={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={slice.onLeash}
                                    onChange={handleChangeCheckbox}
                                    id="onLeash"
                                    color={"default"}
                                />
                            }
                            label="On leash"
                        />
                    </Box>
                </Box>
                <Box mt={2}>
                    <Divider/>
                </Box>
                <Box mt={4} display="flex" justifyContent="flex-end">
                    <Box mr={2}>
                        <Button onClick={onCancelEdit} variant="outlined" color="secondary">Cancel</Button>
                    </Box>
                    <Box mr={2}>
                        <Button onClick={() => onSaveSlice(slice)} variant="outlined" color="secondary">Save</Button>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default SliceEdit;