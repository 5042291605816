import {
    ADD_TRAIL,
    ADD_TRAIL_FULFILLED,
    ADD_TRAIL_REJECTED,
    DELETE_TRAIL,
    DELETE_TRAIL_FULFILLED,
    DELETE_TRAIL_REJECTED,
    SUBSCRIBE_TRAILS,
    SUBSCRIBE_TRAILS_FULFILLED, SUBSCRIBE_TRAILS_UPDATING,
    UPDATE_TRAIL,
    UPDATE_TRAIL_FULFILLED,
    UPDATE_TRAIL_REJECTED
} from "./actions";
import {protocolStore} from "../../../config/firebase-config";

export function fetchTrails(protocolId) {
    return function (dispatch) {
        dispatch({type: SUBSCRIBE_TRAILS});

        protocolStore.doc(protocolId).collection('trails')
            .orderBy('order', 'asc')
            .onSnapshot((snapshot) => {
                dispatch({type: SUBSCRIBE_TRAILS_UPDATING});
                let trails = [];
                snapshot
                    .docChanges()
                    .forEach(change =>
                        trails.push(
                            {
                                item: {
                                    ...change.doc.data(),
                                    id: change.doc.id
                                },
                                type: change.type
                            }
                        )
                    );

                dispatch({type: SUBSCRIBE_TRAILS_FULFILLED, payload: trails});
            });
    }
}

export function addTrail(protocolId, trail) {
    return function (dispatch) {
        dispatch({type: ADD_TRAIL});

        protocolStore.doc(protocolId).collection('trails').add(trail).then((result) => {
            dispatch({type: ADD_TRAIL_FULFILLED});
        }).catch((error) => {
            dispatch({type: ADD_TRAIL_REJECTED, payload: error});
        })
    }

}

export function updateTrail(protocolId, trail) {
    return function (dispatch) {
        dispatch({type: UPDATE_TRAIL});

        protocolStore.doc(protocolId).collection('trails').doc(trail.id).update(trail).then((result) => {
            dispatch({type: UPDATE_TRAIL_FULFILLED, payload: trail})
        }).catch((error) => {
            dispatch({type: UPDATE_TRAIL_REJECTED, payload: error});
        });

    }
}

export function deleteTrail(protocolId, trail) {
    return function (dispatch) {
        dispatch({type: DELETE_TRAIL});
        protocolStore.doc(protocolId).collection('trails').doc(trail.id).delete().then(result => {
            dispatch({type: DELETE_TRAIL_FULFILLED, payload: trail});
        }).catch(error => {
            dispatch({type: DELETE_TRAIL_REJECTED, error});
        })
    }
}
