import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyD9sE_NjkcwbsFGccGNJ0ohVqRzK4nX60k",
    authDomain: "store.logs4dogs.com",
    databaseURL: "https://logs4dogs.firebaseio.com",
    projectId: "logs4dogs",
    storageBucket: "logs4dogs.appspot.com",
    messagingSenderId: "725366119968",
    appId: "1:725366119968:web:24181ad263eaa693"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});

export const protocolStore = firebase.firestore().collection("protocols");

export default firebase;