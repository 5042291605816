import _ from "lodash";


function getAdds(changes) {
    return changes.filter(change => change.type === 'added').map(item => item.item);
}

function getDeletes(changes) {
    return changes.filter(change => change.type === 'removed').map(item => item.item);
}

function getUpdates(changes) {
    return changes.filter(change => change.type === 'modified').map(item => item.item);
}

function mergeLists(newList, currentList) {
    return [
        ...newList,
        ...currentList
            .filter(item =>
                newList.filter(updatedItem =>
                    item.id === updatedItem.id)
                    .length === 0),
    ]
}

export default function updateImmutableStateList(currentState, changes, sortFunction) {

    let newStateList = [];
    const added = getAdds(changes);
    const deleted = getDeletes(changes);
    const modified = getUpdates(changes);

    if (added.length > 0) {
        newStateList = mergeLists(added, currentState);
    }

    if (modified.length > 0) {
        newStateList = mergeLists(modified, currentState);
    }

    if (deleted.length > 0) {
        newStateList = [...currentState];
        deleted.forEach((d => _.remove(newStateList, (trail) => d.id === trail.id)));
    }

    return sortFunction(newStateList);
}


