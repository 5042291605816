import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {fetchProtocols} from "../../data/redux/actions/protocol";
import {Fab, makeStyles} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Link} from "react-router-dom";
import ProtocolList from "./protocol_list";

const useStyles = makeStyles(theme => ({
    addProtocolButton: {
        margin: theme.spacing(1),
        top: theme.spacing(10),
        right: theme.spacing(3),
        position: "absolute"
    }
}));
const ProtocolContainer = () => {

    const classes = useStyles();
    const AddProtocolLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} to="/protocols/new"/>);

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProtocols());
    }, [dispatch]);

    return (
        <div>
            <Fab color="secondary" aria-label="add" component={AddProtocolLink} className={classes.addProtocolButton}>
                <AddIcon/>
            </Fab>
            <ProtocolList/>
        </div>
    );
};

export default ProtocolContainer;